import * as THREE from 'three';
import gsap from 'gsap';

class Sea {
  get width () {
    return window.innerWidth;
  }

  get height () {
    return 400;
  }

  constructor () {
    this.step = 0;
    this.verticesX = 80;
    this.verticesY = 15;
    this.geometries = {};
    this.materials = {};
    this.meshes = {};
    this.lights = {};
    this.container = document.createElement('div');
    this.container.className = 'canvas-container';
    this.geom = null;
    this.intensity = 1;
    this.ambientLight = {};
    this.raycaster = new THREE.Raycaster();
    this.intersect = null;
    this.INTERSECTED = null;
    this.mouse = new THREE.Vector2();

    this.initCamera();
    this.initScene();
    this.initLights();
    this.initSea();
    this.initRenderer();
    this.initListener();

    (this.loop = this.loop.bind(this))();
  }

  buildObject (name, fnGeom, fnMat) {
    this.geometries[name] = fnGeom.call(this);
    this.materials[name] = fnMat.call(this);
    this.meshes[name] = new THREE.Mesh(
      this.geometries[name],
      this.materials[name]
    );
    return this.meshes[name];
  }

  initCamera () {
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.width / this.height,
      0.1,
      1000
    );

    this.camera.position.set(0, -400, 75);

    // this.camera.rotation.x = 50;
  }

  initScene () {
    this.scene = new THREE.Scene();
  }

  initLights () {
    const directional = new THREE.DirectionalLight(0xffffff);
    directional.position.set(1, 1, 3);
    this.lights.directional = directional;
    // this.scene.add(directional);
    const ambient = new THREE.AmbientLight(0x5000ff, 0);
    this.lights.ambient = ambient;
    // this.scene.add(ambient);

    const white = new THREE.AmbientLight(0xffffff, 0.2);
    this.lights.white = white;
    this.scene.add(
      this.lights.directional,
      this.lights.ambient,
      this.lights.white
    );
  }

  initSea () {
    this.scene.add(
      this.buildObject(
        'sea',
        () => {
          // console.log(this);
          this.geom = new THREE.PlaneGeometry(
            this.width * 2,
            this.height * 2,
            this.verticesX,
            this.verticesY
          );
          this.geom.vertices.forEach(v => {
            v.x = v.x + Math.random() * 10;
            v.y = v.y + Math.random() * 10;
          });
          return this.geom;
        },
        () => {
          return new THREE.MeshStandardMaterial({
            color: 0x303030,
            flatShading: true
          });
        }
      )
    );
  }

  initRenderer () {
    document.getElementById('sea').appendChild(this.container);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.renderer.domElement.id = 'canvas';
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setClearColor(0x353535, 0);
    this.renderer.setSize(this.width, this.height);
    this.container.appendChild(this.renderer.domElement);
  }

  initListener () {
    document.addEventListener(
      'mousemove',
      event => this.onMouseMove(event, this),
      false
    );
    window.addEventListener('resize', () => this.onWindowResize(this), false);
    document
      .querySelector('#canvas')
      .addEventListener(
        'mouseenter',
        event => this.onMouseEnter(event, this),
        false
      );
    document
      .querySelector('#canvas')
      .addEventListener(
        'mouseout',
        event => this.onMouseOut(event, this),
        false
      );
  }

  onWindowResize (_this) {
    _this.camera.aspect = _this.width / _this.height;
    _this.camera.updateProjectionMatrix();
    _this.renderer.setSize(_this.width, _this.height);
  }

  onMouseEnter (event, _this) {
    gsap.to(_this.camera.position, {
      duration: 2,
      z: 60,
      y: -380,
      ease: 'Power1.easeInOut'
    });

    gsap.to(_this, 3, {
      intensity: 1.5,
      ease: 'Power1.easeInOut'
    });

    gsap.to(_this.lights.ambient, 3, {
      intensity: 0.6,
      ease: 'Power1.easeInOut'
    });
  }

  onMouseOut (event, _this) {
    gsap.to(_this.camera.position, {
      duration: 1,
      z: 75,
      y: -400,
      ease: 'Power1.easeInOut'
    });

    gsap.to(_this, {
      duration: 3,
      intensity: 1,
      ease: 'Power1.easeInOut'
    });

    gsap.to(_this.meshes.sea.rotation, {
      duration: 1,
      y: 0,
      x: 0
    });

    gsap.to(_this.lights.ambient, {
      duration: 2,
      intensity: 0,
      ease: 'Power1.easeInOut'
    });
  }

  onMouseMove (event, _this) {
    const rect = document.querySelector('#canvas').getBoundingClientRect();
    if (
      event.clientX - rect.left < rect.width &&
      event.clientY >= rect.top &&
      event.clientY - rect.top < rect.height
    ) {
      const centerX = _this.width * 0.5;
      const centerY = _this.height * 0.5;
      const mouseTolerance = 0.05;

      const tiltX = -((event.clientY - centerY) / centerY) * mouseTolerance;
      const tiltY = -((event.clientX - centerX) / centerX) * mouseTolerance;

      gsap.to(_this.meshes.sea.rotation, {
        duration: 2,
        x: tiltX
      });

      gsap.to(_this.meshes.sea.rotation, {
        duration: 2,
        y: tiltY
      });
      _this.mouse.x = (event.clientX / _this.width) * 2 - 1;
      _this.mouse.y = -((event.clientY - rect.top) / _this.height) * 2 + 1;
    }
  }

  loop () {
    requestAnimationFrame(this.loop);
    this.raycaster.setFromCamera(this.mouse, this.camera);

    // this.intersects = this.raycaster.intersectObject(this.meshes.sea);

    this.camera.lookAt(this.scene.position);
    let i = 0;
    for (let ix = 0; ix < this.verticesX; ix++) {
      for (let iy = 0; iy < this.verticesY; iy++) {
        // this.meshes.hull.position.z = 4;
        // this.meshes.hull.rotation.y = Math.sin((ix + this.step) * 2) / 4;
        this.meshes.sea.geometry.vertices[i++].z =
          (Math.sin((ix + this.step) * 2) * 8 +
            Math.cos((iy + this.step) * 1.5) * 10) *
          this.intensity;
        this.meshes.sea.geometry.verticesNeedUpdate = true;
      }
    }
    this.step += 0.01 * this.intensity;
    this.renderer.render(this.scene, this.camera);
  }
}

export default Sea;
