import Sea from './sea.js';
import $ from 'jquery';
import 'slick-carousel';
import 'tilt.js';
require('./typing-animation.js');

(function () {
  const e = [
    'background: linear-gradient(#5000ff, #3e1598)',
    'border: 1px solid #5000ff',
    'color: white',
    'display: block',
    'line-height: 20px',
    'text-align: center',
    'font-weight: bold'
  ].join(';');

  console.log('%c Wallrus Loves you ! ❤️', e);

  function hideIt (element) {
    if (document.querySelector(element)) {
      document.querySelector(element).classList.add('hidden');
    }
  }

  function showIt (element) {
    document.querySelector(element).classList.remove('hidden');
  }

  /**
   * Sidebar functions
   */
  function openSidebar () {
    document.querySelector('.sidebar').classList.add('open');
    document.querySelector('#sidebar-backdrop').classList.add('show');
  }

  function closeSidebar () {
    document.querySelector('.sidebar').classList.remove('open');
    document.querySelector('#sidebar-backdrop').classList.remove('show');
  }

  function setActiveStep (step) {
    if (step === 2) {
      document.querySelector('#sidebar-menu').classList.add('step-2-active');
    } else {
      document.querySelector('#sidebar-menu').classList.remove('step-2-active');
    }
  }

  document.querySelector('#open-sidebar').addEventListener('click', function () {
    openSidebar();
  });

  document
    .querySelectorAll('.close-sidebar')
    .forEach( btn => {
      btn.addEventListener('click', function () {
        if (
          !document
            .querySelector('#sidebar-menu')
            .classList.contains('step-2-active')
        ) {
          closeSidebar();
        }
        setActiveStep(1);
      });
    });

  document
    .querySelector('#sidebar-backdrop')
    .addEventListener('click', function () {
      closeSidebar();
      setActiveStep(1);
    });

  if ( document.querySelector('#learn-more-how-we-do-it') ) {
    document
      .querySelector('#learn-more-how-we-do-it')
      .addEventListener('click', function (event) {
        event.preventDefault();
        document.querySelector('#how-we-do-it').scrollIntoView({ behavior: 'smooth' });
      });
  }

  document
    .querySelector('#what-we-do-link')
    .addEventListener('click', function (event) {
      event.preventDefault();
      setActiveStep(2);
    });

  document
    .querySelector('#contact-link')
    .addEventListener('click', function (event) {
      event.preventDefault();
      closeSidebar();
      document.querySelector('footer').scrollIntoView({ behavior: 'smooth' });
    });

  /* Service Slider */
  if ($('.slider-service')) {
    $('.slider-service').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      prevArrow: '<div class="arrow-prev"></div>',
      nextArrow: '<div class="arrow-next"></div>'
    });
  }
  /* General sliders */
  $('.sliders').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: '<div class="arrow-prev"></div>',
    nextArrow: '<div class="arrow-next"></div>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  /* Back to top */
  const backTop = document.querySelector('#back-to-top');
  const scrollDuration = 700;

  if (backTop) {
    backTop.addEventListener('click', function (event) {
      event.preventDefault();
      !window.requestAnimationFrame
        ? window.scrollTo(0, 0)
        : scrollTop(scrollDuration);
    });
  }

  function scrollTop (duration) {
    const start = window.scrollY || document.documentElement.scrollTop;
    let currentTime = null;

    const animateScroll = function (timestamp) {
      if (!currentTime) currentTime = timestamp;
      const progress = timestamp - currentTime;
      const val = Math.max(
        Math.easeInOutQuad(progress, start, -start, duration),
        0
      );
      window.scrollTo(0, val);
      if (progress < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };

    window.requestAnimationFrame(animateScroll);
  }
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  /* Contact form */
  const contactForm = document.querySelector('#contact-form');
  const contactFormButton = contactForm.querySelector('#contact-button');
  let contactFormSendingInProgress = false;

  function initForm (form) {
    const button = form.querySelector('.form-button');
    const inputs = form.querySelectorAll('.form-input');
    button.value = 'send';
    button.classList.remove('sending');
    button.classList.remove('sent');
    button.classList.remove('error');

    for (let i = 0; i < inputs.length; ++i) {
      inputs[i].value = '';
    }

    contactFormSendingInProgress = false;
  }

  function sentSuccessful (form) {
    const button = form.querySelector('.form-button');
    button.value = 'sent';
    button.classList.remove('sending');
    button.classList.add('sent');
  }

  function sentError (form) {
    const button = form.querySelector('.form-button');
    button.value = 'error';
    button.classList.remove('sending');
    button.classList.add('error');
  }

  function postData (url = '', data = {}, form) {
    // Default options are marked with *
    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        // 'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json; charset=utf-8'
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(response => {
        return response;
      })
      .then(response => {
        setTimeout(function () {
          if (response.status === 200) {
            sentSuccessful(form);
          } else {
            sentError(form);
          }
          setTimeout(function () {
            initForm(form);
          }, 2000);
        }, 2000);
      });
  }

  if (contactFormButton) {
    contactFormButton.addEventListener('click', function (event) {
      if (contactForm[0].checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        if (!contactFormSendingInProgress) {
          contactFormSendingInProgress = true;
          const data = {
            name: contactForm.querySelector('#contact-name').value,
            email: contactForm.querySelector('#contact-email').value,
            message: contactForm.querySelector('#contact-message').value
          };
          contactFormButton.value = 'sending';
          contactFormButton.classList.add('sending');

          postData(`/ask-information?lang=${contactForm.getAttribute('data-lang')}`, data, contactForm);
        }
      }
    });
  }

  /* Preload Header image */

  function displayFirstSectionImage () {
    preloadImage.remove();
    document.querySelector('#first-section-image-our').classList.add('ready');
  }

  const preloadImage = document.querySelector('#preload-header');
  if (preloadImage) {
    if (preloadImage.complete) {
      displayFirstSectionImage();
    } else {
      if (preloadImage.addEventListener) {
        preloadImage.addEventListener('load', function () {
          displayFirstSectionImage();
        });
      } else {
        preloadImage.attachEvent('onload', function () {
          displayFirstSectionImage();
        });
      }
    }
  }

  /* Tilt effect */
  if ($('.tilt')) {
    $('.tilt').tilt({
      reset: false
    });
  }

  /* Jobs - Join the team Form */

  if (document.querySelector('body').classList.contains('jobs')) {
    const joinTheTeamForm = document.querySelector('#join-the-team-form');
    const file = document.querySelector('#join-the-team-file');

    file.addEventListener('change', function (event) {
      joinTheTeamForm.querySelector('.btn').innerHTML =
        event.target.files[0].name;
    });

    if (
      window.location.search !== '' &&
      window.location.search.indexOf('success') > -1
    ) {
      sentSuccessful(joinTheTeamForm);
      setTimeout(
        document.querySelector('section.join-the-team').scrollIntoView(),
        100
      );
      setTimeout(function () {
        initForm(joinTheTeamForm);
      }, 4000);
    }
    if (
      window.location.search !== '' &&
      window.location.search.indexOf('error') > -1) {
      sentError(joinTheTeamForm);
      setTimeout(
        document.querySelector('section.join-the-team').scrollIntoView(),
        100
      );
      setTimeout(function () {
        initForm(joinTheTeamForm);
      }, 4000);
    }
  }

  const cList = document.querySelector('body').classList;
  if (cList.contains('creative-front-end-developer') ||
       cList.contains('ruby-php-fullstack-developer') ||
       cList.contains('stage-3d')) {
    const joinTheTeamForm = document.querySelector('#join-the-team-form-by-post');
    const file = document.querySelector('#join-the-team-file-by-post');

    file.addEventListener('change', function (event) {
      joinTheTeamForm.querySelector('.btn').innerHTML =
        event.target.files[0].name;
    });

    if (
      window.location.search !== '' &&
      window.location.search.indexOf('success') > -1
    ) {
      sentSuccessful(joinTheTeamForm);
      setTimeout(
        document.querySelector('section.join-the-team-by-post').scrollIntoView(),
        100
      );
      setTimeout(function () {
        initForm(joinTheTeamForm);
      }, 4000);
    }
    if (
      window.location.search !== '' &&
      window.location.search.indexOf('error') > -1
    ) {
      sentError(joinTheTeamForm);
      setTimeout(
        document.querySelector('section.join-the-team-by-post').scrollIntoView(),
        100
      );
      setTimeout(function () {
        initForm(joinTheTeamForm);
      }, 4000);
    }
  }

  if (document.querySelector('body').classList.contains('why-us')) {
    function hideActiveSlide () {
      document
        .querySelector('.carousel-link.active')
        .classList.remove('active');
      const activeSlide = document.querySelector('.slide.active');
      activeSlide.classList.remove('active');
      setTimeout(function () {
        activeSlide.classList.remove('d-block');
      }, 150);
    }

    function showSlide (target) {
      target.classList.add('active');
      const targetId = '#' + target.getAttribute('data-target');
      target = document.querySelector(targetId);
      target.classList.add('d-block');
      setTimeout(function () {
        target.classList.add('active');
      }, 150);
    }

    const carouselLinks = document.querySelectorAll('.carousel-link');

    for (let i = 0; i < carouselLinks.length; ++i) {
      carouselLinks[i].addEventListener('click', function (event) {
        event.preventDefault();
        if (this.classList.contains('active')) {
          return;
        }

        hideActiveSlide();
        showSlide(event.currentTarget);
      });
    }

    const anchorLinks = document.querySelectorAll('.anchor');

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener('click', function (event) {
        event.preventDefault();
        // console.log(event.currentTarget);
        document
          .querySelector(event.currentTarget.getAttribute('href'))
          .scrollIntoView({ behavior: 'smooth' });
      });
    }

    $('.slick-testimonials').slick({
      slidesToShow: 1,
      //   arrows: false,
      nextArrow:
        '<div class="slick-arrow-next">next<span class="arrow-next"></span></div>'
    });
  }

  if (document.querySelector('.slick-partners')) {
    $('.slick-partners').slick({
      centerMode: true,
      slidesToShow: 9,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      draggable: false, // important image in brand-activations to show
      pauseOnFocus: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  }

  /**
   * Hidden functions
   */
  let step = 0;
  const touch1 = 'ArrowDown';
  const touch2 = 'ArrowUp';
  let timer = null;

  function tryAgain () {
    step = 0;
    hideIt('#NGLb3');
    setTimeout(function () {
      if (document.querySelector('#NGLb3')) {
        document.querySelector('#NGLb3').remove();
      }
    }, 200);
  }

  document.addEventListener(
    'keydown',
    event => {
      const keyName = event.key;

      if (
        ((step === 0 || step === 1 || step === 3) && keyName === touch1) ||
        (step === 2 && keyName === touch2)
      ) {
        step += 1;
        if (step === 1) {
          timer = setTimeout(function () {
            tryAgain();
          }, 1000);
        }
        if (step === 3) {
          const NG = document.createElement('Img');
          NG.classList.add('hidden');
          NG.id = 'NGLb3';
          NG.src = './images/NGLb3.gif';
          document.querySelector('body').appendChild(NG);
        }
        if (step === 4) {
          clearTimeout(timer);
          showIt('#NGLb3');
          setTimeout(function () {
            tryAgain();
          }, 2000);
        }
      } else {
        step = 0;
      }
    },
    false
  );

  if (document.querySelector('body').classList.contains('home')) {
    new Sea(); // eslint-disable-line
  }
})();
